import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    component: function () {
      return import('../views/DashboardView.vue')
    }
  },
  {
    path: '/dashboard',
    component: function () {
      return import('../views/DashboardView.vue')
    }
  },
  {
    path: '/planning',
    component: function () {
      return import('../views/PlanningView.vue')
    },
  },
  {
    path: '/job/:id/:tab?',
    component: function () {
      return import('../views/JobView.vue')
    },
  },
  {
    path: '/allprojects',
    component: function () {
      return import('../views/AllProjectsView.vue')
    }
  },
  {
    path: '/project/:projectid',
    component: function () {
      return import('../views/ProjectView.vue')
    }
  },
  {
    path: '/customers',
    component: function () {
      return import('../views/CustomersView.vue')
    }
  },
  {
    path: '/kpi',
    component: function () {
      return import('../views/KPIView.vue')
    }
  },
  {
    path: '/useradministration',
    component: function () {
      return import('../views/UserAdministrationView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
