import { createStore } from 'vuex'

export default createStore({
  state: {
    onlineStatus: false,
    userInformation: {},
  },
  getters: {
  },
  mutations: {
    setOnlineStatus(state, value) {
      state.onlineStatus = value;
    },
    setUserInformation(state, value) {
      state.userInformation = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
