<template>
  <v-app>
    <div v-if="loggedin">
      <v-app-bar color="blue-darken-2" density="compact">
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title class="text-left text-sm">Norican Service Platform</v-toolbar-title>
        <!--
        <v-btn variant="text" icon="mdi-magnify"></v-btn>

        <v-btn variant="text" icon="mdi-filter"></v-btn>
        -->

        <v-btn variant="text" :icon="onlineStatus ? 'mdi-earth' : 'mdi-earth-off'"
          :color="onlineStatus ? 'green' : 'yellow'"></v-btn>

      </v-app-bar>

      <v-navigation-drawer v-model="drawer" location="left">
        <v-card class="mx-auto">
          <v-list>
            <v-list-item prepend-avatar="https://cdn.vuetifyjs.com/images/john.png" :title=userInformation.fullname
              :subtitle=userInformation.department>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list :lines="false" density="compact" nav>
            <template v-for="(item, i) in menuitems" :key="i">
              <v-list-item v-if="((item.mobile & isMobile()) | !isMobile())" class="tiny-width" color="blue-darken-2"
                :to="item.link" link>
                <template v-slot:prepend>
                  <v-icon :icon="item.icon"></v-icon>
                </template>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
          <v-divider></v-divider>

        </v-card>
        <v-card style="position: fixed; bottom: -4px; padding: 4px; width: 100%;">
          <v-btn block rounded="true" size="small" color="blue" class="mt-2" @click="logout()"
            :disabled="!onlineStatus">Log
            out</v-btn>
          <p class="text-center"><small>
              Powered by RAMGAARD:NET
            </small>
          </p>
        </v-card>
      </v-navigation-drawer>

      <v-main>

        <router-view />

      </v-main>
    </div>

    <div v-else>
      <div>
        <v-card class="mx-auto mt-8 mb-8 pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
          <h3>
            Norican Service Platform
          </h3>
          <div class="text-subtitle-1 text-medium-emphasis">Account</div>

          <v-text-field autofocus="true" density="compact" v-model="login_email" v-on:keyup.enter="login()"
            placeholder="Email address" prepend-inner-icon="mdi-email-outline" variant="outlined"></v-text-field>

          <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
            Password
          </div>

          <v-text-field :append-inner-icon="passwordvisible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="passwordvisible ? 'text' : 'password'" v-model="login_password" v-on:keyup.enter="login()"
            density="compact" placeholder="Enter your password" prepend-inner-icon="mdi-lock-outline" variant="outlined"
            @click:append-inner="passwordvisible = !passwordvisible"></v-text-field>

          <a class="text-caption text-decoration-none text-blue" href="#" rel="noopener noreferrer" target="_blank">
            Forgot login password?</a>

          <v-card class="mb-12" color="surface-variant" variant="tonal">
            <v-card-text class="text-medium-emphasis text-caption">
              Warning: After 3 consecutive failed login attempts, you account
              will be temporarily locked for 1 hour. You can click "Forgot login
              password?" to reset the login password.
            </v-card-text>
          </v-card>

          <v-card v-if="!onlineStatus" class="mb-12" color="red" variant="tonal">
            <v-card-text class="text-medium-emphasis text-caption">
              Error: You can't login while you are offline and does not have internet access
            </v-card-text>
          </v-card>

          <v-btn :disabled="!onlineStatus" block class="mb-8" color="blue" size="large" variant="tonal" @click="login()">
            Log In
          </v-btn>

          <v-card-text class="text-center">
            <a class="text-blue text-decoration-none" href="#" rel="noopener noreferrer" target="_blank">
              Sign up now <v-icon icon="mdi-chevron-right"></v-icon>
            </a>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <div class="text-center">
      <v-snackbar v-model="showNotification" :timeout="showNotificationTimeout">
        {{ showNotificationText }}
        <template v-slot:actions>
          <v-btn color="blue" variant="text" @click="showNotification = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: 'App',
  data: () => ({
    loggedin: false,
    windowWidth: 0,

    showNotification: false,
    showNotificationTimeout: 2500,
    showNotificationText: '',
    answer: {},
    drawer: true,
    group: null,
    passwordvisible: false,
    login_email: '',
    login_password: '',
    menuitems: [
      {
        text: "Dashboard",
        link: "/dashboard",
        icon: "mdi-view-dashboard-variant-outline",
        mobile: true,
        offline: true,
      },
      {
        text: "Planning",
        link: "/planning",
        icon: "mdi-calendar-month-outline",
        mobile: false,
        offline: false,
      },
      {
        text: "All Projects",
        link: "/allprojects",
        icon: "mdi-sitemap-outline",
        mobile: true,
        offline: false,
      },
      {
        text: "Customers",
        link: "/customers",
        icon: "mdi-domain",
        mobile: true,
        offline: false,
      },
      {
        text: "KPI's",
        link: "/kpi",
        icon: "mdi-chart-bar-stacked",
        mobile: false,
        offline: false,
      },
      {
        text: "User administration",
        link: "/useradministration",
        icon: "mdi-account-multiple",
        mobile: true,
        offline: false,
      },
    ],
  }),
  methods: {

    updateOnlineStatus(e) {
      const type = e.type;

      this.onlineStatus = type === 'online';

      this.$store.commit("setOnlineStatus", window.navigator.onLine);

      this.showNotification = true;
      this.showNotificationTimeout = 2500;
      this.showNotificationText = 'You are now ' + type;

    },
    login() {

      if (this.login_email == 'rasmus.ramgaard@noricangroup.com' && this.login_password == 'disatest') {
        this.loggedin = true;
        this.$cookies.set("online", { userid: 1, fullname: 'Rasmus Ramgaard', roles: ['admin'] });
      } else {

        this.showNotification = true;
        this.showNotificationTimeout = 5000;
        this.showNotificationText = 'Unable to login - please check email and password';

      }

    },
    logout() {
      this.loggedin = false;
      this.$cookies.remove("online");
      this.login_email = '';
      this.login_password = '';
    },
    isMobile() {
      if (window.innerWidth < 800)
        return true
      else
        return false
    }
  },
  beforeMount() {
    this.tmp_online = this.$cookies.get("online");
    if (this.tmp_online != null) {
      this.loggedin = true;
    }
  },
  computed: {
    ...mapState(["onlineStatus", "userInformation"])
  },
  mounted() {
    window.document.title = "Norican Service Platrorm";

    this.$store.commit("setOnlineStatus", window.navigator.onLine);

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    this.$store.commit("setUserInformation", {
      id: 1,
      initials: "RRAM",
      email: "rasmus.ramgaardnoricangroup.com",
      fullname: "Rasmus Ramgaard",
      department: "14140 Electrical Engineering",
      roles: ["admin"]
    });
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  }
};
</script>

<style>
a.tiny-width .v-list-item__prepend {
  width: 32px;
}
</style>


