
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'

// Moment
import moment from 'moment';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import colors from 'vuetify/lib/util/colors'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

import VueCookies from 'vue-cookies';

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi', // This is already the default value - only for display purposes
  },
  theme: {
    defaultTheme: "light",
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: colors.blue.darken1, // #E53935
          secondary: colors.blue.lighten4, // #FFCDD2
        }
      },
    },
  },
})

createApp(App).use(store).use(router).use(vuetify).use(moment).use(VueAxios, axios).use(VueCookies, { expires: '7d' }).mount('#app')